export const ConfigUtil = {
    authDataKey: 'sysAuthData',
    sysDataKey: 'sysAppData',
    authUrl: '/account',
    homeUrl: '/tabs/home',
    dashboardUrl: '/dashboard',
    analyticUrl: '/analytic',
    shopUrl: '/shop',
    profileUrl: '/profile',
    profileStatusUrl: '/profile/status',
    contactUrl: '/contact',
    eventtUrl: '/event',
    notificationUrl: '/notification',
    projectUrl: '/tabs/project',
    advisorUrl: 'tabs/advisor',
    associateUrl: 'tabs/associate',
    fanUrl: 'tabs/fan',
    eventUrl: 'event',
    manageProjectUrl: '/tabs/project/manage',
    manageEventUrl: '/event/manage',
    manageShopUrl: '/shop/manage',
    productUrl: '/shop',
    roomUrl: '/tabs/room',
};
