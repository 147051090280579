export class AuthModelUtil {
    public constructor(
        private authToken: string,
        private authTokenExpirationTime: any
    ) {}

    public getAuthToken() {
        if (!this.authTokenExpirationTime || this.authTokenExpirationTime <= new Date().getTime()) {
            return null;
        }
        return this.authToken;
    }

    public getAuthTokenDuration() {
        if (!this.getAuthToken()) {
            return 0;
        }

        return this.authTokenExpirationTime - new Date().getTime();
    }
}
