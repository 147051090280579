import { Action } from '@ngrx/store';

export const ADD_CART_TOTAL = 'ADD_CART_TOTAL';
export const DELETE_CART_TOTAL = 'ADD_CART_TOTAL';
export const ADD_WISHLIST_TOTAL = 'ADD_WISHLIST_TOTAL';
export const DELETE_WISHLIST_TOTAL = 'DELETE_WISHLIST_TOTAL';

export class AddCartTotal implements Action {
  public readonly type = ADD_CART_TOTAL;
  public constructor(public payload: number) {}
}

export class DeleteCartTotal implements Action {
  public readonly type = DELETE_CART_TOTAL;
  public constructor(public payload: number) {}
}

export class AddWishlistTotal implements Action {
  public readonly type = ADD_WISHLIST_TOTAL;
  public constructor(public payload: number) {}
}

export class DeleteWishlistTotal implements Action {
  public readonly type = DELETE_WISHLIST_TOTAL;
  public constructor(public payload: number) {}
}

export type ShopActions = AddCartTotal | DeleteCartTotal | AddWishlistTotal | DeleteWishlistTotal;
