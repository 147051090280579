import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NgCalendarModule  } from 'ionic2-calendar';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiServiceUtil } from './common/utils/api.service.util';
import { AuthServiceUtil } from './common/utils/auth.service.util';
import { ToastService } from './common/utils/toast.service.util';
import { InterceptorService } from './common/utils/interceptor.service';
import { shopReducer } from './shop/store/shop.reducer';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
     BrowserModule,
     HttpClientModule,
     IonicModule.forRoot(),
     AppRoutingModule,
     NgCalendarModule,
     StoreModule.forRoot({sysShop: shopReducer}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    AuthServiceUtil,
    ApiServiceUtil,
    ToastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
