import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
    public constructor(
        private toastCtrl: ToastController
    ){}

    public toastMessage(dataObj: any) {
        this.toastCtrl
          .create({
          header: dataObj.header,
          message: dataObj.message,
          position: dataObj.position || 'middle',
          color: dataObj.color || 'success',
          duration: dataObj.duration || 3000,
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
                /*if(dataObj.handler === 'deleteCart') {

                }*/
              }
            }
          ]
        })
        .then(toastEl => {
          toastEl.present();
        }).catch((errors) => {
            console.log(`Cannot perform operation on ${dataObj.section}`);
        });
     }
}
