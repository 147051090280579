import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Plugins, Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { AuthServiceUtil } from './common/utils/auth.service.util';
import { ConfigUtil } from './common/utils/config.util';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private authSubscription: Subscription;
  private previousAuthState = false;

  public constructor(
    private alertCtrl: AlertController,
    private platform: Platform,
    private router: Router,
    private authServiceUtil: AuthServiceUtil,
  ) {
    this.initializeApp();
  }

  public ngOnInit() {
    this.authSubscription = this.authServiceUtil.userIsAuthenticated().subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
        this.router.navigateByUrl(ConfigUtil.authUrl);
      }
      this.previousAuthState = isAuth;
    });
  }

  public ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
    });
  }

  public myProfile() {
    this.router.navigateByUrl(ConfigUtil.profileUrl);
  }

  public myStatus() {
    this.router.navigateByUrl(ConfigUtil.profileStatusUrl);
  }

  public myNotification() {
    this.router.navigateByUrl(ConfigUtil.notificationUrl);
  }

  public onLogout() {
    this.alertCtrl.create(
      {header: 'Logout',
       message: 'Are you sure want to logout?',
       buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            return true;
          }
        },
        {text: 'Yes',
          handler: () => {
            this.authServiceUtil.removeAuthData();
            return this.router.navigateByUrl(ConfigUtil.authUrl);
          }
        }
       ]}).then(alertEl => {
         alertEl.present();
       }).catch((errors: any) => {
        
       });
  }
}
