import * as ShopActions from './shop.actions';

const initialState = {
  cartTotal: 0,
  wishListTotal: 0,
};

export const shopReducer = (state = initialState, action: ShopActions.ShopActions) => {
  switch(action.type) {
    case ShopActions.ADD_CART_TOTAL:
      return {...state, cartTotal: action.payload};
    case ShopActions.DELETE_CART_TOTAL:
      return {...state, cartTotal: action.payload};
    case ShopActions.ADD_WISHLIST_TOTAL:
      console.log(action.payload);
      return {...state, wishListTotal: action.payload};
    case ShopActions.DELETE_WISHLIST_TOTAL:
        return {...state, wishListTotal: action.payload};
    default:
      return {...state};
  }
};
