import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardUtil } from './common/utils/auth.guard.util';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  }, {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( (m) => m.AccountPageModule),
  }, {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( (m) => m.DashboardPageModule),
    canLoad: [AuthGuardUtil],
  }, {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactPageModule),
  }, {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( (m) => m.ProfilePageModule),
    canLoad: [AuthGuardUtil],
  }, {
    path: 'event',
    loadChildren: () => import('./event/event.module').then( (m) => m.EventPageModule),
    canLoad: [AuthGuardUtil],
  }, {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( (m) => m.NotificationPageModule),
    canLoad: [AuthGuardUtil],
  },
  {
    path: 'analytic',
    loadChildren: () => import('./analytic/analytic.module').then( (m) => m.AnalyticPageModule),
    canLoad: [AuthGuardUtil],
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then( (m) => m.ShopPageModule),
    canLoad: [AuthGuardUtil],
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
